<template>
	<div class="flv_video">
		<video id="my-video" controls autoplay muted width="300px" height="200px">    
		    </video>
	    <!-- <button @click="play">播放</button> -->
	</div>
</template>

<script>
import flvjs from 'flv.js'
export default {
  name: 'FlvVideo',
   data () {
      return {
  	  flvPlayer:null
      }
    },
    mounted() {
        this.startPlay()
    },
	destroyed() {
		console.log('关闭视频源')
		console.log(this.flvPlayer)
		if (this.flvPlayer) {
		  this.flvPlayer.destroy();
		}   
    },
    methods:{
		startPlay() {
		    if (flvjs.isSupported()) {
		        const videoElement = document.getElementById("my-video");
		        this.flvPlayer = flvjs.createPlayer(
		            {
		                type: "flv",
		                isLive: true,
		                hasAudio: false,
		                //必须与node搭建的流媒体服务器中的http的端口和推流的参数吻合
						// url: 'http://1011.hlsplay.aodianyun.com/demo/game.flv'
		                url: 'http://r.ossrs.net/live/bbb.flv'
						// url:'http://10.139.19.55:18080/live/livestreams.flv'
		            },
		            {
		                enableStashBuffer: true,
		                stashInitialSize: 128
		            }
		        );
		        this.flvPlayer.attachMediaElement(videoElement)
		        this.flvPlayer.load()
		        this.flvPlayer.play()
		    } else {
		        console.log("flvjs不支持")
		    }           
		},
        play () {
          this.flvPlayer.play();
        }
      }
  

}

</script>

<style  scoped="scoped">
.flv_video{
	width: 100%;
	height: 100%;
}
.flv_video video{
	width: 100%;
	height: 100%;
}
</style>
