import { render, staticRenderFns } from "./AgriculturalLandDataMonitoring.vue?vue&type=template&id=48df7448&scoped=true&"
import script from "./AgriculturalLandDataMonitoring.vue?vue&type=script&lang=js&"
export * from "./AgriculturalLandDataMonitoring.vue?vue&type=script&lang=js&"
import style0 from "./AgriculturalLandDataMonitoring.vue?vue&type=style&index=0&id=48df7448&scoped=scoped&lang=css&"
import style1 from "./AgriculturalLandDataMonitoring.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48df7448",
  null
  
)

export default component.exports